/**
 * 全局缓存vue实例 可以让vue实例在任何地方访问
 */
let _instance = {};

const vueGlobalInstance = {
    set(instance){
        _instance = instance;
    },
    get(){
        return _instance;
    },
};
export default vueGlobalInstance;