import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import user from '@/store/modules/user';
import changeUmap from "./modules/changeUmap.js";
import dataset from "./modules/dataset";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    changeUmap,
    dataset,
  },
});

