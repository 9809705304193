import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import '@/style/scss/common.scss';
import ElementUI from 'element-ui';
import '@/style/theme/index.css';
import '@/svgIcons'; // svgIcons
import locale from 'element-ui/lib/locale/lang/en';
import vueGlobalInstance from '@/utils/vueGlobalInstance';
Vue.prototype.bus = new Vue();
Vue.config.productionTip = false;
Vue.use(ElementUI,{locale});
new Vue({
  router,
  store,
  beforeCreate() {
    vueGlobalInstance.set(this);
  },
  render: h => h(App),
}).$mount('#app');
