import Vue from 'vue';
import VueRouter from 'vue-router';
import common from './common';

Vue.use(VueRouter);

const routes = [
  ...common,
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

// 捕获push replace中的错误
// 当然在replace中的错误也是可以相同的进行捕获
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {return originalPush.call(this, location, onResolve, onReject);}
  return originalPush.call(this, location).catch(err => err);
};
// replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {return originalReplace.call(this, location, onResolve, onReject);}
  return originalReplace.call(this, location).catch(err => err);
};

export default router;
