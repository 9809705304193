import {Session} from "@/utils/storage";
const datasetModule = {
    namespaced: true,
    state: {
        cellTypesOptions: [],
        allColorByOtions: [],
        colorByModelValue: [],
        colorByModelValueOfCellTypes: [],
        colorByLastModelValueOfCellTypes: '',
        currentDimension: 2, // 当前数据集维度
        currentDimensionName: 'X_umap', // 当前数据集维度名称
        allColorByOtionsWithCategories: {}, // 正确筛选后分类，该分类带有category
        currentFileName: Session.get('currentFileName'), // 当前所打开的文件名
        currentDegTaskId: '',
        currentPathTaskId: '',

    },
    mutations: {
        changeColorByOptionsM(state, data) {
            state.allColorByOtions = data;
            const res = data.find(item => item.value === 'cell types');
            if (res) {
                state.cellTypesOptions = res.children;
            }
        },
        changeColorByModelValueM(state, data) {
            state.colorByModelValue = data;
            if (data && data[0] === 'cell types') {
                state.colorByModelValueOfCellTypes = data;
                state.colorByLastModelValueOfCellTypes = data[1];
            }
        },
        changeCurrentDimensionInfoM(state, data) {
            state.currentDimension = data.currentDimension;
            if(data.currentDimensionName) {
                state.currentDimensionName = data.currentDimensionName;
            }
        },
        changeAllColorByOtionsWithCategoriesM(state, data) {
            state.allColorByOtionsWithCategories = data;
        },
        setCurrentOpenFileName(state, name) {
            state.currentFileName = name;
            Session.set('currentFileName', name);
        },
        setCurrentDegTaskId(state, task_id) {
            state.currentDegTaskId = task_id;
        },
        setCurrentPathTaskId(state, task_id) {
            state.currentPathTaskId = task_id;
        },
    },
    actions: {
        changeColorByOptionsA({ commit }, data) {
            commit('changeColorByOptionsM', data);
        },

        changeColorByModelValueA({ commit }, data) {
            commit('changeColorByModelValueM', data);
        },
        changeAllColorByOtionsWithCategoriesA({ commit }, data) {
            commit('changeAllColorByOtionsWithCategoriesM', data);
        },
        changeCurrentDimensionInfoA({ commit }, data) { // 改变当前维度信息
            commit('changeCurrentDimensionInfoM', data);
        },
        setCurrentOpenFileNameByUser({ commit }, name) {
            commit('setCurrentOpenFileName', name);
        },
        setCurrentDegTaskIdByUser({commit}, task_id) {
            commit('setCurrentDegTaskId', task_id);
        },
        setCurrentPathTaskIdByUser({commit}, task_id) {
            commit('setCurrentPathTaskId', task_id);
        },
    },
};

export default datasetModule;