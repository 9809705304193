
const state = {
    token: "",
    options: [],
    roles: [],
    commentCount: 0,
    currentUsr: {},
};

const mutations = {
    SET_TOKEN: ( token) => {
        state.token = token;
    },
    SET_USER_OPTIONS: (options) => {
        state.options = options;
    },
    SET_ROLES: (roles) => {
        state.roles = roles;
    },
    SET_COMMENT_COUNT: (count) => {
        state.commentCount = count;
    },
    RESET_USR: (usr) => {
        state.currentUsr = usr;
    },
};

const actions = {

    // //用户信息刷新，用于在编辑用户之后及时更新用户信息
    // resetUsrInfo({ commit }, usr) {
    //     commit('RESET_USR', usr);
    // },

    // // user login
    // login({ commit }, userInfo) {
    //     const { userName, password } = userInfo;
    //     return new Promise((resolve, reject) => {
    //         login({
    //             username: userName,
    //             password: password,
    //         }, function(res) {
    //             if (Number(res.data.code) === 0) {
    //                 commit('SET_TOKEN', res.data.data.token);
    //                 res.data.data.time = new Date().getTime();
    //                 res.data.data.userName = _isEmpty(res.data.data.userName) ? res.data.data.nick_name : res.data.data.userName;
    //                 res.data.data.photo = _isEmpty(res.data.data.photo) ? '' : res.data.data.photo;
    //                 const userInfos = res.data.data;
    //                 server.saveUserInfo(userInfos);
    //                 commit('SET_ROLES', userInfos.roles);
    //                 commit("SET_USER_OPTIONS", userInfos);
    //             }
    //             resolve(res.data);
    //         }, function(error) {
    //             reject(error);
    //         });
    //     });

    // },
    // // user logout
    // logout({ commit, state }) {
    //     // console.log('lastUserName is=>',user_uuid);
    //     // return new Promise(async(resolve, reject) => {
    //         const user_uuid = JSON.parse(Session.get('userUuid'));
    //         commit('SET_TOKEN', '');
    //         removeToken();
    //         Session.clear();
    //         resetRouter();
    //         server.delUserInfo();
    //         // resolve();
    //         //保存上一个退出用户的uuid
    //         Session.set('userUuid',user_uuid);
    //         store.dispatch("routesList/setRoutesList", []); // 清空 vuex 路由列表缓存
    //     // });
    // },

    setTokenA({ commit }, token) {
        commit('SET_TOKEN', token);
    },
    // reg({ commit }, userInfo) {
    //     return new Promise((resolve, reject) => {
    //         reg(userInfo, function(res) {
    //             if (parseInt(res.data.code) === 0) {
    //                 commit('SET_TOKEN', res.data.data.token);
    //                 res.data.data.time = new Date().getTime();
    //                 res.data.data.user_name = _isEmpty(res.data.data.user_name) ? res.data.data.nick_name : res.data.data.user_name;
    //                 res.data.data.phonenumber = _isEmpty(res.data.data.phonenumber) ? '' : res.data.data.phonenumber;
    //                 const userInfos = res.data.data;
    //                 commit('SET_ROLES', userInfos.roles);
    //                 commit("SET_USER_OPTIONS", userInfos);
    //                 this.$message.error()
    //             } else {
    //             }
    //             resolve(res.data.code);
    //         }, function(error) {
    //             reject(error);
    //         });
    //     });

    // },

    // // remove token
    // resetToken({ commit }) {
    //     return new Promise(resolve => {
    //         commit('SET_TOKEN', '');
    //         removeToken();
    //         resolve();
    //     });
    // },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};