export default [

    {
        path: '/login',
        name: 'login',
        disable: false,
        isShow: true,
        component: () => import(/* webpackChunkName: "login" */'@/views/login/index.vue'),
    },
    {
        path: '/celeviz',
        component: () => import(/* webpackChunkName: "celeviz" */'@/components/pageLayout/index.vue'),
        children: [
            {
                path: '/recentFile',
                name: 'SingleronRecentFile',
                disable: false,
                isShow: true,
                component: () =>
                    import ('@/views/recentFile/index.vue'),
                meta: {
                    title: 'Recent file portal',
                    icon: 'recent_file',
                    activeIcon: 'recent_file_selected',
                    isActive: true,
                    requireAuth: true,
                },
            },
            {
                path: '/viewData',
                name: 'SingleronViewData',
                disable: true,
                isShow: true,
                component: () =>
                    import('@/views/viewData/index.vue'),
                    meta: {
                        title: 'View data',
                        icon: 'search',
                        activeIcon: 'search_selected',
                        isActive: false,
                        requireAuth: true,
                    },
            },
            {
                path: '/geneExpression',
                name: 'SingleronGeneExpression',
                disable: true,
                isShow: true,
                component: () =>
                    import ('@/views/geneExpression/index.vue'),
                meta: {
                    title: 'Gene Expression',
                    icon: 'geneExpression',
                    activeIcon: 'geneExpressionSelected',
                    isActive: false,
                    requireAuth: true,
                },
            },
            {
                path: '/cellular_composition',
                name: 'SingleronCellularComposition',
                disable: true,
                isShow: true,
                component: () =>
                    import ('@/views/CellularComposition/SingleronMainCellularComp'),
                meta: {
                    title: 'Cellular composition',
                    icon: 'cellular_composition',
                    activeIcon: 'cellular_composition_selected',
                    isActive: false,
                    requireAuth: true,
                },
            },
            {
                path: '/differential_expression',
                name: 'SingleronDifferentialExpression',
                disable: true,
                isShow: true,
                component: () =>
                    import ('@/views/DifferentialExpression/SingleronMainDifferentComp'),
                meta: {
                    title: 'Differential expression',
                    icon: 'differential_expression',
                    activeIcon: 'differential_expression_selected',
                    isActive: false,
                    requireAuth: true,
                },
            },
            {
                path: '/cellQuality',
                name: 'SingleronCellQualityQC',
                disable: true,
                isShow: true,
                component: () =>
                import ('@/views/cellQuality/expressionQC/index.vue'),
                meta: {
                  title: 'Cell quality',
                  icon: 'cell_quality',
                  activeIcon: 'cell_quality_selected',
                  isActive: false,
                  requireAuth: true,
                },
            },
            {
                path: '/pathway_enrichment',
                name: 'SingleronPathwayEnrichment',
                disable: true,
                isShow: true,
                component: () =>
                    import ('@/views/PathwayEnrichment/SingleronMainPathwayEntry'),
                meta: {
                    title: 'Pathway enrichment',
                    icon: 'pathway_enrichment',
                    activeIcon: 'pathway_enrichment_selected',
                    isActive: false,
                    requireAuth: true,
                },
            },
            {
                path: '/celltypeRelationship',
                name: 'SingleronCelltypeRelationship',
                disable: true,
                isShow: true,
                component: () =>
                  import ('@/views/CelltypeRelationship/index.vue'),
                  meta: {
                    title: 'Celltype relationship',
                    icon: 'venn',
                    activeIcon: 'venn_selected',
                    isActive: false,
                    requireAuth: true,
                  },
            },
        ],
    },
    { path: '*', 
        component: () => import(/* webpackChunkName: "NotFoundPage" */'@/views/NotFoundPage/index.vue'),
    },
];