export const colorPanel = {
    Default: [
        "#0067AA", "#FF7F00", "#00A23F", "#FF1F1D",
        "#A763AC", "#B45B5D", "#FF8AB6", "#B6B800",
        "#01C1CC", "#85D5F8", "#FFC981", "#C8571B",
        "#C6CCC3", "#727272", "#EFC800", "#8A5626",
        "#502E91", "#59A4CE", "#344B2B", "#FBE29D",
        "#FDD6E6", "#849C8C", "#F07C6F", "#599CFF",
        "#40D486", "#6D8299", "#FF9B6A", "#3D56B2",
        "#99D6CB", "#E76496", "#B1CA83", "#6B4F4F",
        '#90AACB', '#DEBA9D', '#C2175B', '#38A3A5',
        '#7E1671', '#2E317C', "#D31027", '#5089C6',
        '#726A95', '#7FB8BB', '#FCB3B3', '#9DD331',
        '#CA8A8B', '#CA935B', '#CCCCFF', "#BBFFB7",
        '#D883FF', '#62DCE3', '#D68060', '#852D37',
        "#F1C4ff", "#FCC515", "#FF7548", '#1FABBF',
        "#248067", "#C355CC", '#B7A091', '#E2D849',
        "#1A6840", "#BEC936", "#D9A40E", "#983680",
        "#1661AB", "#964D22", "#5B4913", "#FA5D19",
        "#74759B", "#7F8284", "#B89485", "#5CB3CC",
        "#D276A3", "#C8ADC4", "#2474B5", "#F8E0B0",
        "#E3B4B8", "#69A794", "#475164", "#93D5DC",
        "#D2357D", "#B78D12", "#93B5CF", "#617567",
        "#3C9566", "#8A6913", "#732E12", "#1491A8",
        "#47484C", "#BC84A8", "#FED71A", "#619AC3",
        '#CF7543', '#A5C3C1', '#F4C7BA', '#DDC871',
        '#DE7622', '#525288', "#7CABB1", '#E8B004',
        '#55BB8A', '#ED5126', '#1491A8', '#B2BBBE',
        '#C35691', '#5BAE23', '#A87BA1', "#87723E",
        '#4E7CA1', '#DA638E', '#FCA104', '#158BB8',
        "#96C24E", "#C27C88", "#9ABEAF", '#82111F',
        "#A7A8BD", "#ED9DB2", '#F9BD10', '#A4ACA7',
        '#E2DDF9', "#ED67AA", "#11FFAA", "#88A23F",
        "#1910E0",
    ],
    Color_v1: [
        '#0067AA', '#FF7F00', '#00A23F', '#FF1F1D', '#A763AC', '#B45B5D', '#FF8AB6', '#B6B800', '#01C1CC',
        '#85D5F8', '#FFC981', '#C8571B', '#727272', '#EFC800', '#8A5626', '#502E91', '#59A4CE', '#344B2B',
        '#FBE29D', '#FDD6E6', '#849C8C', '#F07C6F', '#000101', '#FF4500', '#6959CD', '#FF8C00', '#ADFF2F',
        '#A020F0', '#2F4F4F', '#FFD700', '#EE1289', '#8B2500', '#4682B4', '#FFDAB9', '#708090', '#836FFF',
        '#CDC673', '#CD9B1D', '#FF6EB4', '#CDB5CD', '#006400', '#008B8B', '#43CD80', '#483D8B', '#66CD00',
        '#CDAD00', '#CD9B9B', '#FF8247', '#8B7355', '#8B3A62', '#68228B', '#CDB7B5', '#CD853F', '#6B8E23',
        '#696969', '#7B68EE', '#9F79EE', '#B0C4DE', '#7A378B', '#66CDAA', '#EEE8AA', '#00FF00', '#EEA2AD',
        '#A0522D', '#000080', '#E9967A', '#00CDCD', '#8B4500', '#DDA0DD', '#EE9572', '#EEE9E9', '#8B1A1A',
        '#8B8378', '#EE9A49', '#EECFA1', '#8B4726', '#8B8878', '#EEB4B4', '#C1CDCD', '#8B7500', '#0000FF',
        '#EEEED1', '#4F94CD', '#6E8B3D', '#B0E2FF', '#76EE00', '#A2B5CD', '#548B54', '#BBFFFF', '#B4EEB4',
        '#00C5CD', '#7FFFD4', '#8EE5EE', '#68838B', '#B9D3EE', '#9ACD32', '#00688B', '#FFEC8B', '#1C86EE',
        '#CDCD00', '#473C8B', '#FFB90F', '#EED5D2', '#CD5555', '#CDC9A5', '#FFE7BA', '#CD661D', '#CDC5BF',
        '#FF8C69', '#8A2BE2', '#CD8500', '#B03060', '#FF6347', '#FF7F50', '#CD0000', '#F4A460', '#FFB5C5',
        '#DAA520', '#CD6889', '#32CD32', '#FF00FF', '#2E8B57', '#CD96CD', '#48D1CC', '#9B30FF', '#1E90FF',
        '#191970', '#E8E8E8', '#68ABCC', '#1881B6', '#8ED14B', '#0173b2', '#274852', '#9A4540', '#E3698A',
        '#719F94', '#E5A84B', '#ECEBC2', '#D92B45', '#3B99D4', '#60C9FF', '#076B82', '#ECC2F1', '#ece133',
        '#A86A16', '#BC4749', '#509192', '#635019', '#89363A', '#BC9F77',
    ],
    Color_v2: [
        '#F0A0FF', '#0075DC', '#993F00', '#4C005C', '#191919', '#005C31', '#2BCE48', '#FFCC99', '#808080',
        '#94FFB5', '#8F7C00', '#9DCC00', '#C20088', '#003380', '#FFA405', '#FFA8BB', '#426600', '#FF0010',
        '#5EF1F2', '#00998F', '#E0FF66', '#740AFF', '#990000', '#FFFF80', '#FFE100', '#FF5005', '#AA0DFE',
        '#3283FE', '#85660D', '#782AB6', '#565656', '#1C8356', '#16FF32', '#F7E1A0', '#E2E2E2', '#1CBE4F',
        '#C4451C', '#DEA0FD', '#FE00FA', '#325A9B', '#FEAF16', '#F8A19F', '#90AD1C', '#F6222E', '#1CFFCE',
        '#2ED9FF', '#B10DA1', '#C075A6', '#FC1CBF', '#B00068', '#FBE426', '#FA0087', '#0000FF', '#FF0000',
        '#00FF00', '#000033', '#FF00B6', '#005300', '#FFD300', '#009FFF', '#9A4D42', '#00FFBE', '#783FC1',
        '#1F9698', '#FFACFD', '#B1CC71', '#F1085C', '#FE8F42', '#DD00FF', '#201A01', '#720055', '#766C95',
        '#02AD24', '#C8FF00', '#886C00', '#FFB79F', '#858567', '#A10300', '#14F9FF', '#00479E', '#DC5E93',
        '#93D4FF', '#004CFF', '#F2F318', '#5A5156', '#E4E1E3', '#F6222E', '#FE00FA', '#16FF32', '#3283FE',
        '#FEAF16', '#B00068', '#1CFFCE', '#90AD1C', '#2ED9FF', '#DEA0FD', '#AA0DFE', '#F8A19F', '#325A9B',
        '#C4451C', '#1C8356', '#85660D', '#B10DA1', '#FBE426', '#1CBE4F', '#FA0087', '#FC1CBF', '#F7E1A0',
        '#C075A6', '#782AB6', '#AAF400', '#BDCDFF', '#822E1C', '#B5EFB5', '#7ED7D1', '#1C7F93', '#D85FF7',
        '#683B79', '#66B0FF', '#3B00FB', '#990F26', '#B33E52', '#CC7A88', '#E6B8BF', '#99600F', '#B3823E',
        '#CCAA7A', '#E6D2B8', '#54990F', '#78B33E', '#A3CC7A', '#CFE6B8', '#0F8299', '#3E9FB3', '#7ABECC',
        '#B8DEE6', '#3D0F99', '#653EB3', '#967ACC', '#C7B8E6', '#333333', '#666666', '#999999', '#CCCCCC',
        '#0067AA', '#FF7F00', '#00A23F', '#FF1F1D', '#A763AC', '#B45B5D'],
    Color_v3: [
        '#CB4042', '#BC9F77', '#ECEBC2', '#719F94', '#68ABCC', '#509192', '#CAACC4', '#D57C66', '#C7802D',
        '#E5A84B', '#87A2B4', '#1881B6', '#274852', '#A57DB7', '#9A4540', '#DD6B4F', '#A64036', '#2E8B57',
        '#154A72', '#607B8F', '#9B8FA7', '#632A1B', '#F0C2A2', '#557073', '#631879', '#F0F8FF', '#FAEBD7',
        '#00FFFF', '#7FFFD4', '#F0FFFF', '#F5F5DC', '#FFE4C4', '#000000', '#FFEBCD', '#0000FF', '#8A2BE2',
        '#A52A2A', '#DEB887', '#5F9EA0', '#7FFF00', '#D2691E', '#FF7F50', '#6495ED', '#FFF8DC', '#DC143C',
        '#00008B', '#008B8B', '#B8860B', '#A9A9A9', '#006400', '#BDB76B', '#8B008B', '#556B2F', '#FF8C00',
        '#9932CC', '#8B0000', '#E9967A', '#8FBC8F', '#483D8B', '#2F4F4F', '#00CED1', '#9400D3', '#FF1493',
        '#00BFFF', '#696969', '#1E90FF', '#B22222', '#FFFAF0', '#228B22', '#FF00FF', '#DCDCDC', '#F8F8FF',
        '#FFD700', '#DAA520', '#808080', '#008000', '#ADFF2F', '#F0FFF0', '#FF69B4', '#CD5C5C', '#4B0082',
        '#FFFFF0', '#F0E68C', '#E6E6FA', '#FFF0F5', '#7CFC00', '#FFFACD', '#ADD8E6', '#F08080', '#E0FFFF',
        '#FAFAD2', '#D3D3D3', '#90EE90', '#FFB6C1', '#FFA07A', '#20B2AA', '#87CEFA', '#778899', '#B0C4DE',
        '#FFFFE0', '#00FF00', '#32CD32', '#FAF0E6', '#800000', '#66CDAA', '#0000CD', '#BA55D3', '#9370DB',
        '#3CB371', '#7B68EE', '#00FA9A', '#48D1CC', '#C71585', '#191970', '#F5FFFA', '#FFE4E1', '#FFE4B5',
        '#FFDEAD', '#000080', '#FDF5E6', '#808000', '#6B8E23', '#FFA500', '#FF4500', '#DA70D6', '#EEE8AA',
        '#98FB98', '#AFEEEE', '#DB7093', '#FFEFD5', '#FFDAB9', '#CD853F', '#FFC0CB', '#DDA0DD', '#B0E0E6',
        '#800080', '#663399', '#FF0000', '#BC8F8F', '#4169E1', '#8B4513', '#FA8072', '#F4A460', '#FFF5EE',
        '#A0522D', '#C0C0C0', '#87CEEB', '#6A5ACD', '#708090', '#FFFAFA',
    ],
};
